import React, { useState } from 'react';
import styled from 'styled-components';
import Sidebar from './Sidebar';

// Define the styled components
const HeaderContainer = styled.header`
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it's above other content */
`;

const Logo = styled.img`
  height: 60px; /* Adjust size as needed */
  width: auto;
`;

const MenuButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

const GoogleTypeHeader: React.FC = () => {
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);

  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

  return (
    <>
      <HeaderContainer>
        <Logo onClick={() => window.location.href = '/'} src="/images/logo-png.png" alt="Company Logo" />
        <MenuButton onClick={toggleSidebar}>☰</MenuButton>
      </HeaderContainer>
      <Sidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />
    </>
  );
};

export default GoogleTypeHeader;
