import React from 'react';
import { FaUser, FaShoppingBag, FaSignOutAlt } from 'react-icons/fa';
import '../css/accountpage.css'; // You can define styles here

import { useAuth } from '../domain/AuthContext';



const AccountPage: React.FC = () => {
    const { isAuthenticated, user, logout } = useAuth();

  // Placeholder functions for handling navigation and logout
  const handleProfileClick = () => {
    console.log('Navigate to User Profile');
  };

  const handleBagsClick = () => {
    console.log('Navigate to My Bags');
  };

  const handleLogoutClick = () => {
    console.log('Perform Logout');
  };

  return (
    <div className="account-page">
      <h1>Account Options</h1>
      <ul className="account-options">
        <li onClick={handleProfileClick}>
          <FaUser className="icon" />
          <span>User Profile</span>
        </li>
        <li onClick={handleBagsClick}>
          <FaShoppingBag className="icon" />
          <span>My Bags</span>
        </li>
        <li onClick={handleLogoutClick}>
          <FaSignOutAlt className="icon" onClick={logout} />
          <span onClick={logout} style={{ cursor: 'pointer', color: 'black' }}>Signout</span>
          </li>
      </ul>
    </div>
  );
};

export default AccountPage;
