import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AuthService from '../services/AuthService';
import HttpInterceptor from '../services/HttpInterceptor';
import { useAuth } from '../domain/AuthContext';
import { useNavigate } from 'react-router-dom';
import { CREATE_BAG, MY_BAG_LIST, ROOT } from './NavigationUrl';

const Oauth2LandingPage = () => {

    const [responseData, setResponseData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { login } = useAuth();
    const navigate = useNavigate();


    useEffect(() => {
        setLoading(true);

        axios.get('/web/api/user/')
       .then(response => {
        AuthService.login(response.data);
        login(response.data);
        console.log(response.data);

          navigate(MY_BAG_LIST)
        setLoading(false);

       }).catch( error => {
        console.log(error);
        setLoading(false);
        navigate(ROOT);
       })

      }, []);
      if (loading) return <div>Loading...</div>;
      
  return (
   <div></div>

  );
};

export default Oauth2LandingPage;