import React, { useEffect, useState } from 'react';
import '../css/bagdetail.css';
import CarrierDetailCard from './CarrierDetailCard';
import { Bag } from '../domain/Bag';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import HttpInterceptor from '../services/HttpInterceptor';
import styled from 'styled-components';


interface LocationState {
  bag: Bag; // Define the prop type
}

const BagDetail: React.FC = () => {
  const location = useLocation();
  const { bagId } = useParams(); // Get bagid from URL parameter
  const [bagDetail, setBagDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const UserImage = styled.img`
  width: 50px; /* Adjusted size for icons */
  height: 50px;
  border-radius: 50%;
  margin: 0 10px 10px 0; /* Horizontal and bottom margin for spacing */
`;
  const formatValue = (value: boolean) => {
    if (typeof value === 'boolean') {
      return value ? '✓' : '✗';
    }
    return value;
  };



  useEffect(() => {

    if (!bagId) {
      // If bagid is not present, set error state and stop further execution
      setLoading(false);
      setError("bags id can;t be empty.");
      return;
    }
   
    HttpInterceptor.get(`/web/api/public/bag/${bagId}`)
    .then(resp => {
        console.log(resp.data);
        setBagDetail(resp.data);
        setLoading(false);
    }).catch( error => {
        setError('Failed to fetch users');
        console.log(error);
        setLoading(false);

    })


  },[]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading bag details: {error}</div>;
  if (!bagDetail) return <div>Bag not found</div>;
  const bag : Bag = bagDetail;

  const handleContactHost = () => {
    alert('Contacting host...');
  };

  return (

    
    <div className="container">



     
    <h1 className="title">
    {bag.originCity} To {bag.destinationCity}
    </h1>

    <div style={{ marginTop: '10px' }} className="user-info">
  <UserImage src={bag.carrier?.profileurl || 'defaultProfileImage.png'} alt="User Profile" />
  <div className="name-container">
    <span className="checkbox">&#x2714;</span> {/* Checkmark symbol */}
    <span className="firstname">{bag.carrier?.firstname || 'Guest'}  ★★★★</span>
  </div>
</div>
<div style={{ marginTop: '10px' }}>
    <p className="date">
      <strong>Going on : </strong>{bag.journeydate}
    </p>
</div>
    <div style={{ marginTop: '10px' }}>
    <p><strong>Descriptions</strong></p>
   
 { bag.description != null ? (
     <p>{bag.description}</p>
    ) : (
      <p> N/A</p>
    )}
 </div>


  <div >
    
    <p><strong>Preferable Items that I can carry</strong></p>
    <div className="key-value-container">
      {Object.entries(bag.preferItems).map(([key, value]) => (
        <div key={key} className="key-value-pair">
          <span className="key">
          <UserImage 
                key={key} 
                src={`/images/icons/${key.replace(/\s+/g, '').toLowerCase()}.png?v=${new Date().getTime()}`} 
                alt={`${key} icon`} 
                title={key} // Tooltip showing the item name
              />
            {key}</span>
          <span className="value">
            {typeof value === 'boolean' ? (
              <span className={`status-icon ${value ? 'checked' : 'unchecked'}`}>
                {formatValue(value)}
              </span>
            ) : (
              value
            )}
          </span>
        </div>
      ))}
    </div>
    </div>

    
  <div style={{ marginTop: '15px' }}>
    <p><strong>Special Instructions</strong></p>
   
 { bag.notesToSender != null ? (
     <p>{bag.notesToSender}</p>
    ) : (
      <p> N/A</p>
    )}
 </div>
      <CarrierDetailCard
        name={bag.createdBy}
        bio={`${bag.createdBy} is an experienced traveler with a passion for providing great experiences`}
        photo={bag.carrier.profileurl} 
        joineddate={bag.carrier.joinedDate}
        reviews="★★★★"
      />

  <div >

   <button style={{ marginTop: '20px' }} onClick={handleContactHost} className="app-button" >
      Contact traveler
    </button>
    </div>

    </div>

    
  );
};

export default BagDetail;
