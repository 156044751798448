import axios from 'axios';
import { useState } from 'react';

const HttpInterceptor = axios.create({
  baseURL: '',
});


// Add a request interceptor
HttpInterceptor.interceptors.request.use(
  config => {
    // You can modify headers or perform other actions before the request is sent
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
HttpInterceptor.interceptors.response.use(

  
  response => {
    // If the request succeeds, return the response directly
    return response;
  },
  error => {
    // If the request encounters an error
    if (error.response) {
      // If the error status is 401 (Unauthorized), redirect the user to the login page
      if (error.response.status === 401) {
        //login page.
     //   window.location.href = 'http://localhost:8085/web/api/oauth2/authorization/google';
      // Change '/login' to your desired URL
      window.location.href='/login';
     
      }
    }
    return Promise.reject(error);
  }
);

export default HttpInterceptor;
