import React, { useState, useEffect } from 'react';
import Select, { MultiValue, SingleValue, ActionMeta } from 'react-select';
import '../css/createbag.css';
import HttpInterceptor from '../services/HttpInterceptor';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../domain/AuthContext';
import AuthService from '../services/AuthService';


// Define types for form values
interface FormValues {
  sourceCountry: SingleValue<{ label: string; value: string }> | null;
  destinationCountry: SingleValue<{ label: string; value: string }> | null;
  sourceCity: SingleValue<{ label: string; value: string }> | null;
  destinationCity: SingleValue<{ label: string; value: string }> | null;
  preferredItems: { label: string; value: string }[];
  description: string;
  date: string;
  senderNotes: string;
}

interface CitiesByCountry {
  [key: string]: string[];
}

const citiesByCountry: CitiesByCountry = {
  India: [
    'Delhi',
    'Mumbai',
    'Bengaluru',
    'Chennai',
    'Kolkata',
    'Hyderabad',
    'Ahmedabad',
    'Pune',
    'Jaipur',
    'Surat',
    'Lucknow',
    'Kanpur',
    'Nagpur',
    'Visakhapatnam',
    'Indore',
    'Thane',
    'Coimbatore',
    'Patna',
    'Ghaziabad',
    'Bhopal',
    'Madurai',
    'Nashik',
    'Rajkot',
    'Varanasi',
    'Meerut',
    'Vijayawada',
    'Aurangabad',
    'Amritsar',
    'Dhanbad',
    'Jodhpur',
    'Bhubaneswar',
    'Salem',
    'Mysuru',
    'Chandigarh',
    'Faridabad',
    'Nagpur',
    'Ranchi',
    'Srinagar',
    'Dehradun',
    'Gurugram',
    'Visakhapatnam',
    'Puducherry',
    'Vellore',
    'Kochi',
    'Guwahati',
    'Agra',
    'Kalyan-Dombivli',
    'Davanagere'
  ],
  Canada: [
    'Toronto',
    'Vancouver',
    'Montreal',
    'Calgary',
    'Ottawa',
    'Edmonton',
    'Halifax',
    'Victoria',
    'Winnipeg',
    'Brampton',
    'Hamilton',
    'Kitchener',
    'London',
    'Mississauga',
    'Quebec City',
    'Saskatoon',
    'Regina',
    'Burnaby',
    'Surrey',
    'St. John\'s',
    'Chilliwack',
    'Richmond',
    'Thunder Bay',
    'Abbotsford',
    'Kamloops',
    'Nanaimo',
    'Lethbridge',
    'Oshawa',
    'Burlington',
    'Gatineau',
    'Saint John',
    'Moncton',
    'Dartmouth',
    'Sherbrooke',
    'New Westminster',
    'Peterborough',
    'Sault Ste. Marie',
    'Niagara Falls',
    'Prince George',
    'Moose Jaw',
    'Whitehorse',
    'Iqaluit',
    'Yellowknife',
    'Fort McMurray',
    'Barrie',
    'Cornwall',
    'Kelowna',
    'Grande Prairie',
    'Brantford',
    'North Bay',
    'Brockville',
    'Vaughan',
    'Camrose',
    'Guelph',
    'Woodstock',
    'Stratford',
    'Dawson Creek',
    'Morden',
    'Steinbach',
    'Airdrie',
    'Spruce Grove'
  ],
  USA: [
    'New York',
    'Los Angeles',
    'Chicago',
    'Dallas',
    'San Francisco',
    'Miami',
    'Atlanta',
    'Houston',
    'Seattle',
    'Philadelphia',
    'Phoenix',
    'San Diego',
    'Minneapolis',
    'Denver',
    'Boston',
    'Portland',
    'Las Vegas',
    'Baltimore',
    'San Antonio',
    'Austin',
    'Sacramento',
    'Cincinnati',
    'Kansas City',
    'Virginia Beach',
    'Atlanta',
    'Milwaukee',
    'Tampa',
    'Pittsburgh',
    'Omaha',
    'Raleigh',
    'Cleveland',
  ],
  UK: [
    'London',
    'Manchester',
    'Edinburgh',
    'Birmingham',
    'Glasgow',
    'Bristol',
    'Liverpool',
    'Leeds',
    'Sheffield',
    'Newcastle',
    'Brighton',
    'Coventry',
    'Nottingham',
    'Belfast',
    'Cardiff',
    'Dundee',
    'Stoke-on-Trent',
    'Plymouth',
    'Derby',
    'Luton',
    'Wolverhampton',
    'Swansea',
    'Bradford',
    'Cambridge',
    'Bournemouth',
    'Aberdeen',
    'Southampton',
  ],
};


const countryOptions = Object.keys(citiesByCountry).map(country => ({ label: country, value: country }));

const getCityOptions = (country: string) => citiesByCountry[country]?.map(city => ({ label: city, value: city })) || [];

const itemOptions = [
  { label: 'Jewellery', value: 'jewellery' },
  { label: 'Laptop', value: 'laptop' },
  { label: 'Toys', value: 'toys' },
  { label: 'Phones', value: 'phones' },
  { label: 'Medicines', value: 'medicines' },
  { label: 'Baby Items', value: 'child_items' },
  { label: 'Documents', value: 'documents' },
  { label: 'Clothes', value: 'clothes' },
  { label: 'Electronic', value: 'electronic' },
  { label: 'Other', value: 'other' }
];

const CreateBag: React.FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated, user, logout } = useAuth();


  const goToMessageBox = (msg:string, bagId?:string) => {
    navigate('/message', {
      state: {
        message: msg,
        bagId:bagId,
        showBackButton: false,
        showFrontButton: false,
        frontButtonLabel: 'view bags',
        backButtonLabel: 'Return',
        frontRoute: '/'

       
      }
    });
  };

  const [formValues, setFormValues] = useState<FormValues>({
    sourceCountry: { label: 'Canada', value: 'Canada' },
    destinationCountry: { label: 'India', value: 'India' },
    sourceCity: null,
    destinationCity: null,
    preferredItems: [],
    description: '',
    date: '',
    senderNotes: ''
  });

  const [formErrors, setFormErrors] = useState<{ sourceCity?: string; destinationCity?: string }>({});
  const [sourceCityOptions, setSourceCityOptions] = useState<{ label: string; value: string }[]>([]);
  const [destinationCityOptions, setDestinationCityOptions] = useState<{ label: string; value: string }[]>([]);
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');


  useEffect(() => {
    
    if (!AuthService.isAuthenticated()) navigate("/login");

  })

  useEffect(() => {
    if (formValues.sourceCountry) {
      setSourceCityOptions(getCityOptions(formValues.sourceCountry.value));
      setFormValues(prevState => ({ ...prevState, sourceCity: null }));
    }
  }, [formValues.sourceCountry]);

  useEffect(() => {
    if (formValues.destinationCountry) {
      setDestinationCityOptions(getCityOptions(formValues.destinationCountry.value));
      setFormValues(prevState => ({ ...prevState, destinationCity: null }));
    }
  }, [formValues.destinationCountry]);



  const handleChange = (
    selectedOption: SingleValue<{ label: string; value: string }> | null,
    name: keyof FormValues
  ) => {
    setFormValues(prevState => {
      const newValues = { ...prevState, [name]: selectedOption };

      if (name === 'sourceCountry') {
        newValues.sourceCity = null;
      } else if (name === 'destinationCountry') {
        newValues.destinationCity = null;
      }

      if (name === 'sourceCity' || name === 'destinationCity') {
        setFormErrors(prevState => ({
          ...prevState,
          [name]: selectedOption ? undefined : `${name.split(/(?=[A-Z])/)[0]} city is required`
        }));
      }

      return newValues;
    });
  };

  const handlePreferredItemsChange = (
    selectedOptions: MultiValue<{ label: string; value: string }>,
    actionMeta: ActionMeta<{ label: string; value: string }>
  ) => {
    setFormValues(prevState => ({
      ...prevState,
      preferredItems: selectedOptions as { label: string; value: string }[]
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let errors = { sourceCity: '', destinationCity: '' };
    let formIsValid = true;

    if (!formValues.sourceCity) {
      errors.sourceCity = 'Source city is required';
      formIsValid = false;
    }

    if (!formValues.destinationCity) {
      errors.destinationCity = 'Destination city is required';
      formIsValid = false;
    }

    if (formValues.sourceCity?.value === formValues.destinationCity?.value) {
      errors.sourceCity = 'Source city and destination city cannot be the same';
      errors.destinationCity = 'Source city and destination city cannot be the same';
      formIsValid = false;
    }

    setFormErrors(errors);

    if (formIsValid) {
      setSubmitStatus('loading');

      const preferredItemsMap = itemOptions.reduce((map, option) => {
        map[option.label] = formValues.preferredItems.some(item => item.label === option.label);
        return map;
      }, {} as Record<string, boolean>);

      const postData = {
        originCountry: formValues.sourceCountry?.value || '',
        originCity: formValues.sourceCity?.value || '',
        destinationCountry: formValues.destinationCountry?.value || '',
        destinationCity: formValues.destinationCity?.value || '',
        preferItems: preferredItemsMap,
        description: formValues.description,
        journeydate: formValues.date,
        notesToSender: formValues.senderNotes
      };

      try {
        const response = await HttpInterceptor.post('/web/api/bags/', postData, {
          headers: { 'Content-Type': 'application/json' }
        });
        const bagId = response.data;
        console.log("generated bag id " + bagId)
        setSubmitStatus('success');
        goToMessageBox('Congratulation !! Your bag has been created Successfully. You can share bags with friend on social media',
              bagId);
      } catch (error) {
        console.error('Error:', error);
        setSubmitStatus('error');
        goToMessageBox('There is error in creating bag. Please trg again. if error persist  contact us .');
      }
    }
  };

  return (
    <div className="form-container">
      <div>
      <h2 className="form-title">Let's create a bag.</h2>
      <form onSubmit={handleSubmit} className="form">
        <div className="form-group">
          <label htmlFor="sourceCountry" className="form-label">Tell us about your travel route.</label>
          <Select
            id="sourceCountry"
            name="sourceCountry"
            options={countryOptions}
            value={formValues.sourceCountry}
            onChange={option => handleChange(option as SingleValue<{ label: string; value: string }>, 'sourceCountry')}
            className="form-control"
            placeholder="Select origin Country"
          />
        </div>

        <div className="form-group">
          <label htmlFor="sourceCity" className="form-label">Tell us city from where you are traveling from?</label>
          <Select
            id="sourceCity"
            name="sourceCity"
            options={sourceCityOptions}
            value={formValues.sourceCity}
            onChange={option => handleChange(option as SingleValue<{ label: string; value: string }>, 'sourceCity')}
            className="form-control"
            placeholder="Select Source City"
          />
          {formErrors.sourceCity && <p className="error-text">{formErrors.sourceCity}</p>}
        </div>

        <div className="form-group">
          <label htmlFor="destinationCountry" className="form-label">Country where you are going to.</label>
          <Select
            id="destinationCountry"
            name="destinationCountry"
            options={countryOptions}
            value={formValues.destinationCountry}
            onChange={option => handleChange(option as SingleValue<{ label: string; value: string }>, 'destinationCountry')}
            className="form-control"
            placeholder="Select Destination Country"
          />
        </div>

        <div className="form-group">
          <label htmlFor="destinationCity" className="form-label">City where you are traveling to</label>
          <Select
            id="destinationCity"
            name="destinationCity"
            options={destinationCityOptions}
            value={formValues.destinationCity}
            onChange={option => handleChange(option as SingleValue<{ label: string; value: string }>, 'destinationCity')}
            className="form-control"
            placeholder="Select Destination City"
          />
          {formErrors.destinationCity && <p className="error-text">{formErrors.destinationCity}</p>}
        </div>

        <div className="form-group">
          <label htmlFor="preferredItems" className="form-label">Tell us what items you prefer to carry in the bag.</label>
          <Select
            id="preferredItems"
            name="preferredItems"
            options={itemOptions}
            value={formValues.preferredItems}
            onChange={handlePreferredItemsChange}
            isMulti
            className="form-control"
            placeholder="Select Preferred Items"
          />
        </div>

        <div className="form-group">
          <label htmlFor="description" className="form-label">Describe about your self, how many bag you have etc.</label>
          <textarea
            id="description"
            name="description"
            value={formValues.description}
            onChange={e => setFormValues(prevState => ({ ...prevState, description: e.target.value }))}
            className="form-control"
            placeholder="Add any additional details here"
          />
        </div>

        <div className="form-group">
          <label htmlFor="date" className="form-label">Tell us about your travel date.</label>
          <input
            id="date"
            name="date"
            type="date"
            value={formValues.date}
            onChange={e => setFormValues(prevState => ({ ...prevState, date: e.target.value }))}
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label htmlFor="senderNotes" className="form-label">Tell us any special instructions to senders i.e picking up stuff, dropping stuff etc</label>
          <textarea
            id="senderNotes"
            name="senderNotes"
            value={formValues.senderNotes}
            onChange={e => setFormValues(prevState => ({ ...prevState, senderNotes: e.target.value }))}
            className="form-control"
            placeholder="Add any additional notes here"
          />
        </div>

        <button type="submit" className="form-button" disabled={submitStatus === 'loading'}>
          {submitStatus === 'loading' ? 'Submitting...' : 'Submit'}
        </button>

        {submitStatus === 'success'}
        {submitStatus === 'error' && <p className="error-text">There was an error creating the bag. Please try again.</p>}
      </form>
    </div>
    </div>
    
  );
};

export default CreateBag;
