import React from 'react';
import '../css/login.css'; // Import CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

const LoginPage = () => {

   
  // Handler function for Google sign-in
  const handleGoogleSignIn = () => {
    const redirectUrl = process.env.REACT_APP_OAUTH2_LOGIN_GOOGLE_API_END_POINT;

    // Implement Google sign-in logic here
    console.log("Sign in with Google clicked");
    if (redirectUrl)
        window.location.href = redirectUrl;
  };

  // Handler function for Facebook sign-in
  const handleFacebookSignIn = () => {
    const redirectUrl = process.env.REACT_APP_OAUTH2_LOGIN_FACEBOOK_API_END_POINT;

    // Implement Facebook sign-in logic here
    console.log("Sign in with Facebook clicked");
    if (redirectUrl)
      window.location.href = redirectUrl;
  };

  return (
   <div className="login-container">
     
      
      <div className="social-buttons-container">
                <button className="google-button" onClick={handleGoogleSignIn}>
      <FontAwesomeIcon icon={faGoogle} />
      <span>Continue with Google</span>
    </button>
 {/* Facebook Sign-In Button */}

 <button className="facebook-button" onClick={handleFacebookSignIn}>
      <FontAwesomeIcon icon={faFacebook} />
      <span>Continue with Facebook</span>
    </button>
       </div>
     
    </div>
  );
};

export default LoginPage;
