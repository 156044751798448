import React from 'react';
import '../css/privacypolicy.css'; // Import the stylesheet

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="privacy-policy-container">
      <header className="privacy-policy-header">
        <h1>Privacy Policy</h1>
        <p>Last updated: September 10, 2024</p>
      </header>
      <main className="privacy-policy-content">
        <section>
          <h2>Introduction</h2>
          <p>
            Welcome to our Privacy Policy page! When you use our website, you trust us with your personal information. We are committed to protecting your privacy and ensuring a safe experience on our site.
          </p>
        </section>
        
        <section>
          <h2>Information We Collect</h2>
          <p>
            We collect various types of information in connection with your use of our website, including personal information, usage data, and more.
          </p>
        </section>
        
        <section>
          <h2>How We Use Your Information</h2>
          <p>
            Your information helps us provide better services and improve your experience on our site. We may use your data for various purposes, including to process transactions, communicate with you, and analyze usage patterns.
          </p>
        </section>

        <section>
          <h2>Data Security</h2>
          <p>
            We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or method of electronic storage is completely secure.
          </p>
        </section>

        <section>
          <h2>Changes to This Privacy Policy</h2>
          <p>
            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
          </p>
        </section>

        <section>
          <h2>Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact us at <a href="mailto:contact@gotbagspace.com">contact@extrabagspace.com</a>.
          </p>
        </section>
      </main>
     
    </div>
  );
};

export default PrivacyPolicy;
