// src/api.ts
import axios from 'axios';
import { Bag } from '../domain/Bag';
import HttpInterceptor from './HttpInterceptor';


const API_URL = '/web/api/bags';

const PUBLIC_API_URL = '/web/api/public/';


export const getMyBags = async (): Promise<Bag[]> => {
  const response = await HttpInterceptor.get(API_URL + '/');
  return response.data;
};

export const getALLBags = async (): Promise<Bag[]> => {
    const response = await axios.get(PUBLIC_API_URL);
    return response.data;
  };

export const getBagById = async (id: string): Promise<Bag> => {
  const response = await axios.get(`${PUBLIC_API_URL}bag/${id}`);
  return response.data;
};

export const deleteBag = async (id: string): Promise<void> => {
  await HttpInterceptor.delete(`${API_URL}/${id}`);
};

export const updateBag = async (id: string, updatedBag: Partial<Bag>): Promise<Bag> => {
  const response = await HttpInterceptor.put(`${API_URL}/${id}`, updatedBag);
  return response.data;
};
