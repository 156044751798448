import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Bag } from '../domain/Bag';
import HttpInterceptor from '../services/HttpInterceptor';
import BagCard from './BagCard';
import { getALLBags } from '../services/bagservice';

const BagList: React.FC = () => {
  const [bags, setBags] = useState<Bag[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    
    getALLBags().then( data => {
      console.log(data);
      setBags(data);
      setLoading(false);
    }).catch( error => {
      setError('Failed to fetch users');

      console.log(error);
    })
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (

    
    <div className="property-list">
    
      {bags.map((bag) => (
        <BagCard key={bag.id} bag={bag} />
      ))}
    </div>
  );
};

export default BagList;
