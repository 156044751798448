// AuthService.js

import HttpInterceptor from "./HttpInterceptor";

class AuthService {
   
    static isAuthenticated() {
      // Check if the user is authenticated (you can implement your own logic here)
      // For example, you might check if a token exists in localStorage or sessionStorage
     // checkAuthenticated();
      return !!sessionStorage.getItem('token');
    }

    static getUserInformation() {
      const tokenStr = sessionStorage.getItem('token');
      if(tokenStr) 
        return JSON.parse(tokenStr);
        // sessionStorage.getItem('token');
    }
  
    static login(token:string) {
      // Store the authentication token in session storage
      sessionStorage.setItem('token', JSON.stringify(token));
    }

    static handleSignOut = async() => {
        // Handle sign out logic
        const response =  HttpInterceptor.get('/web/api/user/logout'); // Use the Axios instance for making API calls
  
        AuthService.logout();
        window.location.href='/';
        
      };

      static handleSignIn = () => {
        // Handle sign out logic
  
        window.location.href='/login';
        
      };
  
    static logout() {
      // Remove the authentication token from session storage
      sessionStorage.removeItem('token');
    }
  }
  
  export default AuthService;
  