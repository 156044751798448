import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../css/pagebottom.css'; // Import custom CSS for styling

const PageBottom: React.FC = () => {
  return (
    <footer className="pagebottom-custom">
      <Container fluid>
        <Row className="justify-content-center align-items-center">
          <Col xs={12} md={6} className="text-center">
            <div className="powered-by">
              <div className="pagebottom-text">Developed & Supported by</div>
              <a href="https://www.armsoftwares.com" target="_blank" rel="noopener noreferrer" className="armsoftware-link">
                <img src="/images/arm-software-logo.png" alt="ArmSoftware Logo" className="armsoftware-logo" />
              </a>
            </div>
          </Col>
          <Col xs={12} md={6} className="text-center">
            <div className="site-links">
              <a href="https://extrabagspace.com/privacypolicy"  target="_blank" rel="noopener noreferrer">Privacy Policy</a>
              <a href="/termscondition" target="_blank" rel="noopener noreferrer">Terms and condition</a>
            </div>
            <p style={{ marginTop: '20px' }} className="pagebottom-text">
              &copy; {new Date().getFullYear()}. All rights reserved. 
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default PageBottom;
