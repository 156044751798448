import React from 'react';
import { Link } from 'react-router-dom';
import { Bag } from '../domain/Bag';
import styled from 'styled-components';
import { capitalizeFirstLetter } from '../utlities/formateText';
import '../css/bagcard.css';
import { FaLuggageCart } from 'react-icons/fa';

interface BagCardProps {
  bag: Bag;
}

const IconImage = styled.img`
  width: 35px; /* Adjusted size for icons */
  height: 35px;
  border-radius: 50%;
  margin: 0 10px 10px 0; /* Horizontal and bottom margin for spacing */
`;

const UserImage = styled.img`
  width: 50px; /* Adjusted size for icons */
  height: 50px;
  border-radius: 50%;
  margin: 0 10px 10px 0; /* Horizontal and bottom margin for spacing */
`;

const CardContainer = styled(Link)`
  text-decoration: none; /* Remove underline from link */
  color: inherit; /* Use inherited color for text */
  border: 1px solid #eaeaea; /* Light border */
  border-radius: 8px; /* Rounded corners */
  overflow: hidden; /* Ensure child elements fit within rounded corners */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2); /* Elevated shadow on hover */
  }
`;

const PropertyCardContent = styled.div`
  padding: 16px; /* Padding inside the card */
`;

const PropertyCardTitle = styled.h3`
  font-size: 20px;
  margin: 0 0 8px; /* Spacing below the title */
  font-weight: bold; /* Make title bold */
`;

const PropertyCardPrice = styled.p`
  font-size: 16px;
  color: black; /* Use a prominent color for price */
  margin: 0 0 4px; /* Spacing below the price */
`;

const PropertyCardRating = styled.p`
  font-size: 14px;
  color: #777; /* Grey color for rating */
  display: flex;
  align-items: center; /* Center items vertically */
`;

const PreferItemsContainer = styled.div`
  display: flex; /* Align items in a row */
  flex-wrap: wrap; /* Allow items to wrap if necessary */
  margin-top: 10px; /* Spacing above */
`;

const BagCard: React.FC<BagCardProps> = ({ bag }) => {
  const preferItems = bag.preferItems || {}; // Default to an empty object if undefined

  return (
    <CardContainer to={`/bagdetail/${bag.uniqueId}`} className="property-card-link">
      <PropertyCardContent>
      <PropertyCardTitle>{bag.originCity} To {bag.destinationCity}</PropertyCardTitle>
        <PropertyCardPrice  style={{ marginTop: '20px' }}><strong>Going on</strong> {bag.journeydate}</PropertyCardPrice>
        <PropertyCardPrice><strong>Status</strong>  {capitalizeFirstLetter(bag.bagStatus)}</PropertyCardPrice>
       
       
        <PropertyCardRating  style={{ marginTop: '20px' }}>
          <UserImage src={bag.carrier?.profileurl} alt="User Profile" />
          {bag.carrier?.firstname}
        </PropertyCardRating>
        <span>★★★★</span>
      </PropertyCardContent>
    </CardContainer>
  );
};

export default BagCard;
