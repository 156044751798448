import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CreateBagFormComponent from '../components/CreateBag';
import LoginPage from '../components/login';
import { BrowserRouter as Router, Route, Routes, Link, BrowserRouter } from 'react-router-dom';
import Oauth2LandingPage from '../components/Oauth2LandingPage';
import BagList from '../components/BagList';
import '../css/table.css'; // Import CSS file for styling
import '../css/bagslist.css'; // Import CSS file for styling
import { ACCOUNT, BAGDETAIL, BAGDETAILEMPTY, CREATE_BAG, LISTBAGS, LOGIN, MY_BAG_LIST, OAUTH2LANDING, PRIVACYPOLICY, ROOT } from '../components/NavigationUrl';
import BagDetail from '../components/BagDetail';
import PrivacyPolicy from '../components/PrivacyPolicy';
import MessageBox from '../components/MessageBox';
import Footer from './footer';
import AccountPage from '../components/AccountPage';
import HomePage from '../components/root';
import Landing from '../components/landing';
import MyBagList from '../components/MyBagList';

const Body: React.FC = () => {
  const abc = () => {
  }
  return (
    <Container>
      <Row className="my-4">
        <Col>
        <BrowserRouter>
          <Routes>
          <Route path={LOGIN} element={<LoginPage />} />
          <Route path={CREATE_BAG} element={<CreateBagFormComponent />} />
          <Route path={OAUTH2LANDING} element={<Oauth2LandingPage />} />
          <Route path={LISTBAGS}element={<BagList/>} />
          <Route path={BAGDETAIL} element={<BagDetail/>}/>
          <Route path={BAGDETAILEMPTY} element={<BagDetail/>}/>
          <Route path={ACCOUNT} element={<AccountPage />} />
          <Route path={ROOT} element={<Landing />} />
          <Route path={PRIVACYPOLICY} element={<PrivacyPolicy/>} />
          <Route path={MY_BAG_LIST} element={<MyBagList/>} />

          <Route path="/message" element={<MessageBox />} />

        </Routes>
      </BrowserRouter>     
   </Col>
      </Row>
    </Container>
  );
};

export default Body;
